.containerPlayer {
  display: none;
}

.containerOpen {
  width: 100%;
  margin: 0 auto;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10;
  top: 0;
  bottom: 0;
  background-color: white;
  overflow-y: auto;
}

.continerInfo {
  padding: 20px 20px 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.img {
  width: 263px;
  height: 268px;
  border-radius: 26px;
  margin: 99px auto 30px;
  object-fit: cover;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin: 0 auto;
}

.btnClose {
  left: 0;
  cursor: pointer;
}

.containerManagement {
  position: relative;
  background-color: #d4e9f5;
  padding: 22px 21px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 69.5%);
}

.container {
  display: flex;
}

.containerIcon {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.volume {
  background-image: url('../../../images_new/volume.png');
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-color: transparent;
  width: 22px;
  height: 16px;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.volumeContainer {
  display: none;
}

.volumeContainerHidden {
  position: absolute;
  width: 24px;
  height: 123px;
  background: #d4e9f5;
  border-radius: 10px;
  display: flex;
  top: -140px;
  left: 15px;
  align-items: center;
  justify-content: center;
}

.valueInput {
  -webkit-appearance: none;
  background: -webkit-linear-gradient(
    bottom,
    #3495cf 0%,
    #3495cf 50%,
    #fff 50%,
    #fff 100%
  );
  width: 3px;
  height: 93px;
  cursor: pointer;
  align-self: center;
  margin: 0;
  border-radius: 10px;
}

/* .valueInput{
    -webkit-appearance: none;
    background-color: #3495CF;
} */

.valueInput::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 50%;
  background-color: #d1d1d8;
  width: 9px;
  height: 9px;
}

.vector {
  background-image: url('../../../images_new/vector.png');
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-color: transparent;
  width: 22px;
  height: 16px;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.dot {
  background-image: url('../../../images_new/dot.png');
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-color: transparent;
  margin-left: 23.5px;
  cursor: pointer;
  padding: 0;
  width: 3px;
  height: 16px;
}

.containerProgress {
  background-color: white;
  width: 100%;
  height: 3px;
  margin: 0 0 10px;
  cursor: pointer;
}

.progress {
  width: 20%;
  background-color: #3495cf;
  height: 3px;
}

.containerTime {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.time {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.containerButton {
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.prev {
  background-image: url('../../../images_new/arrowLeft.png');
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-color: transparent;
  width: 23px;
  height: 25px;
  cursor: pointer;
  margin-right: 40px;
  padding: 0;
}

.play {
  border: none;
  background-color: transparent;
  width: 58px;
  height: 58px;
  cursor: pointer;
  padding: 0;
}

.next {
  background-image: url('../../../images_new/arrowRight.png');
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-color: transparent;
  width: 23px;
  height: 25px;
  cursor: pointer;
  margin-left: 40px;
  padding: 0;
}

.wrapper {
  padding-bottom: 60px;
  overflow-y: hidden;
}

.containerCard {
  color: #363636;
  max-width: 420px;
  margin: auto;
  position: relative;
}

.containerImage {
  width: 100%;
  height: 373px;
  position: relative;
}

.buttonMap {
  width: 289px;
  height: 51px;
  position: absolute;
  bottom: -51px;
  left: 50%;
  background-color: #3495cf;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  color: #f8f7f9;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
}

.btnText {
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
}

.containerArrow {
  width: 34px;
  height: 34px;
  position: absolute;
  left: 20px;
  top: 30px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  border: none;
}

.info {
  padding: 0 20px;
  margin-bottom: 32px;
}

.cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about {
  margin: 16px 0;
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 0 0 19px;
}

.containerInfo {
  display: flex;
  margin-bottom: 13px;
}

.containerInfo:last-of-type {
  margin-bottom: 0;
}

.iconImg {
  width: 21px;
  height: 21px;
  margin-right: 8px;
  border-radius: 50%;
  object-fit: cover;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 0 7px;
}

.description {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  height: 58px;
  overflow: hidden;
}

.descriptionHidden {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #363636;
}

.containerCity {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.nameCity {
  margin: 0 0 0 12px;
  font-weight: 400;
  font-size: 16px;
  color: #363636;
}

.textAuthot {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  border-bottom: 1px solid #363636;
}

.containerFeatures {
  display: flex;
  background-color: #d4e9f5;
  border-radius: 10px;
  width: 100%;
  margin: 0 0 24px;
  height: 50px;
  justify-content: space-evenly;
}

.containerElement {
  display: flex;
  align-items: center;
}

.containerPrice {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: #f8f7f9;
  width: 100%;
  height: 86px;
  justify-content: space-around;
  z-index: 2;
}

.textPrice {
  color: #767676;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}

.price {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.points {
  margin-left: 5%;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
}

.pointsHidden {
  margin-left: 5%;
  overflow: hidden;
}

.pointsContainer {
  width: 217px;
  height: 323px;
  margin-right: 20px;
}

.pointsImgContainer {
  position: relative;
  width: 217px;
  height: 255px;
  border-radius: 10px;
  margin-bottom: 14px;
}

.pointsImg {
  object-fit: cover;
  width: 217px;
  height: 255px;
  border-radius: 10px;
  margin-bottom: 14px;
}

.pointsNum {
  position: absolute;
  left: 12px;
  top: 12px;
  color: #3495cf;
  background-color: #ffffff;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
}

.player {
  margin: 0 0 0 5%;
  height: 40px;
  display: flex;
  align-items: center;
}

.playBtn {
  height: 40px;
  width: 40px;
  background-color: #3495cf;
  border-radius: 50px;
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.playerTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3495cf;
}

.pointsTitle {
  width: 217px;
  height: 54px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #363636;
}

.button {
  width: 100%;
  text-align: center;
}
