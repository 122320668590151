.containerList{
    overflow-y: auto;
}

.show{
    display: block;
    cursor: pointer; 
    border: none;
    margin: 31.5px auto 0;
    background-color: transparent;
    color: #3495CF;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.button_click{
    display: none;
    cursor: pointer; 
    border: none;
    margin-bottom: 5px;
    background-color: transparent;
}