.exMap__container {
  height: 394px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 auto;
}
.placemark_layout_container {
  position: relative;
  font-family: 'Open Sans';
  margin-top: -40px;
  margin-left: -15.5px;
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  width: 31px;
  height: 40px;
  text-align: center;
  background: url('../../images_new/map_icon_dis.svg');
  background-repeat: no-repeat;
}
.placemark_layout_container_active {
  background: url('../../images_new/map_icon.svg');
}
.placemark_layout {
  position: absolute;
  top: 8px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.exMapRoutes {
  width: 100%;
  padding: 0 50px;
  box-sizing: border-box;
  margin: 0 auto;
}
.exMap__containerFull {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: white;
}

.playButtonMap {
  height: 40px;
  width: 40px;
  background-color: #3495cf;
  border-radius: 50px;
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 50px;
  right: 20px;
}
.backButtonMap {
  height: 40px;
  width: 40px;
  background-color: #3495cf;
  border-radius: 50px;
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 50px;
  left: 20px;
}
