.mapCard_old {
  display: grid;
  width: 100%;
  padding: 0 0 12px 0;
  grid-template-columns: 20% 70%;
  grid-template-rows: auto;
  column-gap: 12px;
  align-items: center;
  background-color: #f5f5f5;
  position: relative;
  border: 1px solid grey;

}
.mapCard:hover{
  cursor: pointer;
}
.mapCard__image_old {
  grid-row-start: 1;
  grid-row-end: 4;
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 4px;
}

.mapCard__title_old {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  padding: 0;
  margin: 0;

}
.mapCard__subtitle_old {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  padding: 0;
  margin: 0;
  max-height: 60px;
  overflow: hidden;
}
