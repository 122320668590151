.searchSwitcher__event {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 48px;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.searchSwitcher__search {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 48px;
  align-items: center;
  justify-content: center;
  padding: 16px 0 20px;
}

.link__wrapper {
  width: 50%;
  text-align: center;
}

.searchSwitcher__button {
  background: transparent;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  color: black;
  text-decoration: none;
  border: none;
}

.active {
  color: #1B8086;
  padding-bottom: 5px;
  border-bottom: 2px solid #1B8086;
}

.hover:hover {
  cursor: pointer;
  opacity: 0.6;
  transition: 0.8s;
}
