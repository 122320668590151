.container {
    color: #363636;
    width: 90%;
    max-width: 375px;
    margin: 52px auto 120px;
}

.title {
    margin: 28px 0 30px 0;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
}

.listElement {
    position: relative;
    margin-bottom: 26px;
    height: 24px;
    display: flex;
    align-items: center;
}

.listText {
    padding: 0;
    margin: 0 0 0 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.lastElement {
    position: absolute;
    right: 0;
}

.titleLinks {
    margin: 40px 0 20px 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
}

.links {
    display: flex;
    justify-content: space-between;
    width: 116px;
}
