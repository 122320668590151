.containerFavorite{
    padding: 37px 0 0;
}

.containerArrow{
    display: flex;
    cursor: pointer;
    margin: 0 0 31px 20px;
}

.title{
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
    margin: 0 0 0 20px;
}

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 199px);
    padding: 0 20px;
    box-sizing: border-box;
}

.text{
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    margin: 0 0 14px;
    text-align: center;
}

.textLink{
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #3495CF;
    cursor: pointer;
    margin: 0;
}

.cantainerList{
    margin-top: 32px;
}