.price {
    display: flex;
    width: 100%;
    margin-bottom: 40px;
    border: 0.5px solid #363636;
    border-radius: 10px;
    align-items: center;
    padding: 18px 10px 19px 12px;
    box-sizing: border-box;
    min-height: 61px;
}

.inputPrice {
    font-size: 16px;
    width: 100%;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    min-height: 23px;
}

.span {
    position: relative;
}

.spanContainer {
    display: flex;
    align-items: center;
}
