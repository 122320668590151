.wrapper {
  padding-bottom: 67px;
  overflow-y: hidden;
}

.containerCard {
  color: #363636;
  max-width: 420px;
  margin: auto;
  position: relative;
}

.containerImage {
  width: 100%;
  height: 373px;
  position: relative;
}

.btn {
  width: 289px;
  height: 51px;
  background-color: #3495cf;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  color: #f8f7f9;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
}

.btnMap {
  position: absolute;
  bottom: -51px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btnComplete {
  margin: 32px auto 0;
}

.containerArrow {
  width: 34px;
  height: 34px;
  position: absolute;
  left: 20px;
  top: 30px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  border: none;
}

.info {
  padding: 0 20px;
  margin-bottom: 37px;
}

.cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about {
  margin: 16px 0;
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 0 0 19px;
}

.containerInfo {
  display: flex;
  margin-bottom: 13px;
}

.containerInfo:last-of-type {
  margin-bottom: 0;
}

.iconImg {
  width: 21px;
  height: 21px;
  margin-right: 8px;
  border-radius: 50%;
  object-fit: cover;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 0 7px;
}

.textAuthor {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  border-bottom: 1px solid #363636;
}

.containerFeatures {
  display: flex;
  background-color: #d4e9f5;
  border-radius: 10px;
  width: 100%;
  margin: 0 0 24px;
  height: 50px;
  justify-content: space-evenly;
}

.containerElement {
  display: flex;
  align-items: center;
}

.containerPrice {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: #f8f7f9;
  width: 100%;
  height: 86px;
  justify-content: space-around;
  z-index: 2;
}

.textPrice {
  color: #767676;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}

.price {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.points {
  margin-left: 5%;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
}

.pointsHidden {
  margin-left: 5%;
  overflow: hidden;
}

.pointsContainer {
  width: 217px;
  margin-right: 20px;
}

.pointsImgContainer {
  position: relative;
  width: 217px;
  height: 255px;
  border-radius: 10px;
  margin-bottom: 14px;
}

.pointsImg {
  object-fit: cover;
  width: 217px;
  height: 255px;
  border-radius: 10px;
  margin-bottom: 14px;
}

.pointsNum {
  position: absolute;
  left: 12px;
  top: 12px;
  color: #3495cf;
  background-color: #ffffff;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
}

.player {
  margin: 24px 0 32px 5%;
  height: 40px;
  display: flex;
  align-items: center;
}

.playBtn {
  height: 40px;
  width: 40px;
  background-color: #3495cf;
  border-radius: 50px;
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.playerTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3495cf;
}

.pointsTitle {
  width: 217px;
  height: 54px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #363636;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.map {
  position: relative;
  margin-bottom: 78px;
}
