.profileEdit {
  padding: 0 20px;
}

.container {
  padding: 24px 0 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.headerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.submitButton {
  border: none;
  background: none;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
  text-align: right;
  text-decoration: underline;
  color: #363636;
  opacity: 0.8;
  padding: 0;
  cursor: pointer;
}

.avatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #f8f7f9;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 52px;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -22px;
  right: 50px;
  width: 101px;
  height: 38px;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0 1px 4px rgba(154, 154, 154, 0.3);
  border-radius: 50px;
}

.buttonText {
  margin-left: 7px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.title {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0;
  text-align: left;
  margin: 24px 0 42px;
  padding: 0;
  width: 100%;
}

.avatarImage {
  height: 162.52392578125px;
  width: 162.5000457763672px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.subtitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0;
  text-align: left;
  width: 100%;
  margin: 0;
  padding: 0;
}

.wrapper {
  margin-bottom: 40px;
  margin-top: 50px;
}

.inputContainer {
  width: 100%;
  padding: 0;
  margin: 32px 0 0;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.formInput {
  border: 0.5px solid #363636;
  border-radius: 10px;
  width: 100%;
  height: 61px;
  padding-left: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: #363636;
  box-sizing: border-box;
}

.formInput:focus {
  outline: none;
}

.formInput::placeholder {
  color: #979798;
}

.form__error {
  position: absolute;
  /* opacity: 0; */
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  color: #ee3465;
  bottom: -20px;
  left: 0;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  /* margin-top: 40px; */
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.infoText {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.emailTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  margin: 0;
  padding: 0;
  color: #363636;
}

.emailText {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  margin: 0;
  padding: 0;
}

.cityText {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin: -4px 0 0;
  padding: 0;
  color: #363636;
}

.boxImage {
  position: relative;
  overflow: hidden;
  height: 162.52392578125px;
  width: 162.5000457763672px;
}
