.container {
  padding: 30px 20px 80px;
  display: flex;
  flex-direction: column;
}

.userContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  position: relative;
}

.userAvatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.boxImage {
  position: relative;
  overflow: hidden;
  height: 60px;
  width: 60px;
}

.userDescription {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.userName {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0;
  text-align: left;
  margin: 0;
  padding: 0;
  color: #363636;
  opacity: 0.8;
}

.userEmail {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  margin: 0;
  padding: 0;
  color: #979798;
  opacity: 0.8;
}

.backButton {
  /* width: 23px;
    height: 23px; */
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-left: auto;
  background: none;
  /* background: url('../../images_new/back_right.svg'); */
  background-repeat: no-repeat;
}

.createButton {
  margin: 36px auto 0;
  width: 100%;
  height: 117px;
  display: flex;
  flex-direction: column;
  border: none;
  filter: drop-shadow(0px 4px 4px rgba(154, 154, 154, 0.3));
  gap: 12px;
  align-items: center;
  background: #ffffff;
  box-shadow: 1px 3px 9px 2px rgba(118, 118, 118, 0.44);
  border-radius: 10px;
  padding: 0;
  cursor: pointer;
}

.createButtonImg {
  width: 40px;
  height: 40px;
  background: url('../../images_new/plus.svg');
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;
  color: #363636;
}

.createButtonText {
  margin: 19px 10px 0 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0;
  text-align: center;
}

.menuSubtitle {
  display: flex;
  flex-direction: row;
  gap: 12px;
  position: relative;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
}

.margin {
  margin-top: 26px;
  margin-bottom: 40px;
}

.menuTitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0;
  text-align: left;
  margin: 16px 0 32px;
}

.menuTitle:first-of-type {
  margin-top: 40px;
}

.menuIconProfile {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuLink {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  margin: 0;
  padding: 0;
  color: #363636;
  opacity: 0.8;
}

.disactive {
  color: #979798;
}
.borderSubtitle {
  padding: 0 0 16px;
  border-bottom: 1px solid #979798;
}
