.searchListPopup {
  width: 100%;
  padding: 0;
  border-top-left-radius: 42px;
  border-top-right-radius: 42px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.searchListLine {
  width: 90%;
  border-bottom: 0.5px solid #c4c4c4;
  padding: 14px 0 0 0;
  margin-bottom: 12px;
}

