.description {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.btn {
  padding: 0;
  background: transparent;
  border: 0;
  color: #979798;
  cursor: pointer;
  font: inherit;
}

.btnHidden {
  display: none;
}
