.mainImg {
    object-fit: contain;
    width: 100%;
    margin: 0 0 16px;
}

.delete {
    position: absolute;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    right: 4px;
    top: 4px;
    border: none;
}

.bigButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 180px;
    box-shadow: 1px 3px 9px 2px rgba(118, 118, 118, 0.44);
    border-radius: 10px;
    border: none;
    cursor: pointer;
    margin: 0 0 20px;
    padding: 0;
}

.spanContainer {
    display: flex;
    align-items: center;
}
