.container{
    display: flex;
    flex-direction: column;
    position: relative;
}
.inputCity{
    min-height: 61px;
    width: 100%;
    border: 0.5px solid #363636;
    border-radius: 10px;
    outline: none;
    padding: 0 0 0 10px;
    box-sizing: border-box;
    margin: 0 0 16px;
}

.error{
    border: 0.5px solid #FE5F55;
    color: #FE5F55;
}

.error::placeholder {
    color: #FE5F55;
}

.containerCityHidden{
    display: none;
}

.containerCity{
    display: block;
    color:#979798; 
    font-size: 16px; 
    font-weight: 500; 
    line-height: 24px;
    margin: 0 auto;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: white;
    max-height: 335px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.text{
    margin: 0;
    border-bottom: 0;
    cursor: pointer;
    color: #363636;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 6px 0 6px 16px;
}

.text:hover{
    background-color: #D4E9F5;
}