.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 20;
}

.content {
  box-sizing: border-box;
  padding: 34px 20px 24px;
  background-color: #ffffff;
  border-radius: 20px 20px 0 0;
  margin-top: 45px;
  width: 100%;
  overflow-y: auto;
  text-align: center;
}

.text {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.textSecondary {
  margin: 67px 0 50px;
  color: #979798;
  font-size: 14px;
  line-height: 20px;
}
