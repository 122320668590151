.tooltip{
    position: absolute;
    width: 275px;
    min-height: 104px;
    background-color: #FFFFFF;
    border-radius: 6px;
    padding: 15px 18px;
    box-sizing: border-box;
    right: 110%;
    top: -170%;
    z-index: 150;
    box-shadow: 0px 4px 13px -5px #4F6573;
}

.tooltipTitle{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 6px;
}

.tooltipText{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
}