.imageWrapper {
  width: 100%;
  min-height: 45vh;
  max-height: 65vh;
  overflow: hidden;
  position: relative;
}

.image {
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 20px;
}

.title {
  margin: 25px auto 16px 20px;
  max-width: 335px;
}

.text {
  margin: 0 auto 28px 20px;
  max-width: 324px;
}

.indicators {
  margin: 0 auto 40px 20px;
  display: flex;
  justify-content: space-between;
  width: 65px;
}

.active {
  padding: 0 !important;
  border: none !important;
  width: 10px;
  height: 10px;
  background: #3495cf;
  border-radius: 50%;
}

.inactive {
  width: 10px;
  height: 10px;
  background: rgba(151, 151, 152, 0.38);
  border-radius: 50%;
}

@media screen and (max-height: 700px) {
  .image {
    position: absolute;
    bottom: 0;
  }

  .indicators {
    margin: 0 auto 20px 20px;
  }

  .title {
    margin: 15px auto 8px 20px;
    max-width: 335px;
  }

  .text {
    margin: 0 auto 18px 20px;
  }
}
