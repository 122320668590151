.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  text-align: center;
}

.codeWrapper {
  margin-top: 200px;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.code {
  margin: 0;
  color: #3495cf;
  font-size: 96px;
  font-weight: 600;
  line-height: normal;
}

.info p {
  margin: 0;
  font-weight: 500;
}

.link {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #3495cf;
  cursor: pointer;
  margin: 0;
  text-decoration: underline;
  opacity: 0.8;
}
