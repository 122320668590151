.slider-list__container {
  position: relative;
}

.slider-list__button-container {
  display: flex;
  justify-content: space-between;
  padding: 11px 0 0;
  position: absolute;
  z-index: 10;
  width: 100%;
}

.slider-list__slider {
  width: 100%;
  aspect-ratio: 16/9;
  /* height: 273px; */
}
