.pointPopupWrapper {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  bottom: 20vh;
  width: 100%;
  overflow: hidden;
  padding: 0 20px;
}

.pointPopup {
  background-color: #ffffff;
  border-radius: 10px;
  width: 334px;
  padding: 5px;
  display: flex;
  gap: 10px;
  margin: 0 auto;
  cursor: pointer;
}

.pointPopup__img {
  width: 100px;
  height: 110px;
  border-radius: 10px;
  object-fit: cover;
  margin: auto 0;
}

.pointPopup__title {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  padding: 0;
  margin: 0;
  margin-top: 3px;
  max-width: 170px;
  text-transform: capitalize;
  overflow-wrap: break-word;
}

.pointPopup__city {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 4px 0 6px;
}

.pointPopup__subtitle {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  padding: 0;
  margin: 0;
  max-height: 60px;
  overflow: hidden;
  text-transform: capitalize;
}

.pointPopup__player {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 175px;
  padding-bottom: 7px;
}
