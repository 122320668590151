.list {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  
  .link {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .linkName {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    padding: 0;
    color: #363636;
    opacity: 0.8;
    width: 75%;
  }