.popup{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}

.popup_opened{
    display: flex;
    margin: 0 auto;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    transition: visibility 0s, opacity 0.5s linear;
}

.containerPopup_opened{
    display: flex;
    flex-direction: column;
    visibility: visible;
    opacity: 1;
    max-width: 420px;
    margin: 0 auto;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(76, 76, 76, 69%);
    align-items: center;
    justify-content: center;
    transition: visibility 0s, opacity 0.5s linear;
    cursor: pointer;
}

.container{
    width: 100%;
    height: 167px;
    background-color: white;
    border-radius: 20px 20px 0 0;
    padding: 30px 20px;
    box-sizing: border-box;
    max-width: 375px;
    display: flex;
    flex-direction: column;
    bottom: 0;
    position: fixed;
}

.downloadPfoto{
    border: none;
    background-color: transparent;
    color: #3495CF;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 35px;
}

.text{
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    margin: 0 0 5px;
}

.text:last-of-type{
    margin-bottom: 0;
}