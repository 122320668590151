.containerCard {
  color: #363636;
  max-width: 420px;
  margin: auto;
  position: relative;
  padding-bottom: 110px;
}

.containerImage {
  width: 100%;
  height: 373px;
  position: relative;
}

.containerLike {
  width: 34px;
  height: 34px;
  position: absolute;
  right: 62px;
  top: 30px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.containerShare {
  width: 34px;
  height: 34px;
  position: absolute;
  right: 20px;
  top: 30px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.containerArrow {
  width: 34px;
  height: 34px;
  position: absolute;
  left: 20px;
  top: 30px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  border: none;
}

.info {
  padding: 0 20px;
  margin-bottom: 37px;
}

.cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about {
  margin: 16px 0;
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 0 0 19px;
}

.containerInfo {
  display: flex;
  margin-bottom: 13px;
}

.containerInfo:last-of-type {
  margin-bottom: 0;
}

.iconImg {
  width: 21px;
  height: 21px;
  margin-right: 8px;
  border-radius: 50%;
  object-fit: cover;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 0 7px;
}

.textAuthot {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  border-bottom: 1px solid #363636;
}

.containerFeatures {
  display: flex;
  background-color: #d4e9f5;
  border-radius: 10px;
  width: 100%;
  margin: 0 0 24px;
  height: 50px;
  justify-content: space-evenly;
}

.containerElement {
  display: flex;
  align-items: center;
}

.containerPrice {
  background-color: #f8f7f9;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 0 20px;  
  box-sizing: border-box;
  width: 100%;
  height: 86px; 
  z-index: 2;  
}

.textPrice {
  color: #767676;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}

.price {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}
