.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.content {
  position: absolute;
  top: 5%;
  left: 0;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  width: 90%;
  height: 95%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 5%;
}

.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px 0 13px 0;
  margin-bottom: 30px;
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
}

.textUnderLine {
  font-weight: 600;
  text-decoration-line: underline;
  cursor: pointer;
}

.btnClose {
  position: absolute;
  left: 0;
  cursor: pointer;
}

.title {
  margin: 0;
  justify-self: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.form {
  display: flex;
  flex-direction: column;
}

.text {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #979798;
}

.textLine {
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.line {
  width: calc(50% - 21px);
  border-bottom: 1px solid #c4c4c4;
}

.popupOverlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.textAgree {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #979798;
}

.textForm {
  color: #363636;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 0;
  margin-bottom: 48px;
}

.link {
  color: #3495cf;
  text-decoration: underline;
}
