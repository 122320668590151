.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.popup_opened {
  display: flex;
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  transition: visibility 0s, opacity 0.5s linear;
  background: rgba(76, 76, 76, 69%);
  max-width: 420px;
}

.container {
  width: 100%;
  background-color: white;
  border-radius: 20px;
  padding: 27px 20px 53px;
  box-sizing: border-box;
  max-width: 375px;
  display: flex;
  flex-direction: column;
}

.btnClose {
  margin: 0 0 39px 4px;
  cursor: pointer;
  width: 17px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 0 0 16px;
  text-align: center;
}

.text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 0 10px;
  text-align: center;
}

.button {
  margin: 0 auto;
  width: 254px;
  height: 51px;
  background: #3495cf;
  border-radius: 30px;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  cursor: pointer;
}

.button:disabled {
  background: #d1d1d8;
  color: #979798;
}
