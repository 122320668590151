@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(-20%, 0px, 0px);
  }

  100% {
    opacity: 1;
    transform: none;
  }

}

.map__popup {
  margin: 0 auto;
  background-color: rgb(255, 255, 255);
  max-width: 780px;
  padding: 0;
  display: none;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 100vh;
}


.mapCoord__container {
  margin: 0 auto;
  /* position: relative; */
}

.mapCoord {
  height: 100vh;
}

.fadeInRight {
  animation-name: fadeInRight;
  opacity: 1;
  display: block;
  animation-duration: 0.7s;
  animation-timing-function: linear;
}

.mapPanel {
  width: 250px;
  max-height: 120px;
  position: fixed;
  border-radius: 20px;
  padding: 10px 10px 0;
  border: 1px solid black;
  margin: 0;
  /* opacity: 0; */
  display: none;
  left: calc(50% - 135px);
  bottom: 50px;
  right: 0;
  font-size: 14px;
  background: rgb(255, 255, 255);
  /* overflow: auto; */
  text-align: center;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.7);
}

#address {
  margin: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
#coords {
  margin: 0;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

#point {
  margin: 20px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #1B8086;
}

.searchMap__button {
  font-family: 'Open Sans';
  width: 181px;
  height: 32px;
  cursor: pointer;
  margin: 10px auto;
  border-radius: 30px;
  border: none;
  background-color: #3495CF;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  display: none;
}
.visible{
  display: block;
}
.searchMap__button:hover {
  opacity: .7;
  transition: .7s;
  
}