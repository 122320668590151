.list {
    width: 90%;
    margin: 0 auto 27px auto;
    padding: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.containerButton{
    display: flex;
    margin-bottom: 87px;
}