.input {
    width: 271px;
    height: 61px;
    border: 0.5px solid #979798;
    border-radius: 10px;
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 1em;
    color: #979798;
}

.input:focus {
    border-color: #363636;
    color: #363636;
    font-weight: 600;
    outline:none;
}

.error {
    font-weight: 300;
    font-size: 14px;
    line-height: 30px;
    position: absolute;
    top: 45px;
    color: red;
}
