.button {
    margin: 0 auto;
    background: transparent;
    border-radius: 30px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #363636;
    opacity: 0.8;
}