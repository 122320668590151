.container {
  padding: 37px 20px 100px;
}

.arrow {
  margin: 0 0 31px;
  width: 12px;
  display: flex;
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
}

.title {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  margin: 0 0 20px;
}
