@keyframes slideInLeft {
  0% {
    transform: translate3d(100%, 0px, 0px);
    visibility: visible;
  }

  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}

.mapCard__opacity {
  z-index: 1;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0px, 100%, 0px);
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.mapCard {
  max-width: 334px;
  width: 100%;
  height: 120px;
  padding: 5px 10px 5px 5px;
  box-sizing: border-box;
  /* display: flex;
  justify-content: flex-start;
  gap: 11px; */
  background: #ffffff;
  border: none;
  border-radius: 10px;
  margin: 0 auto;
  position: relative;
  filter: drop-shadow(0px 4px 4px rgba(118, 118, 118, 0.36));
}

.mapCard__mainWrap {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  gap: 11px;
}

.mapCard__slider {
  width: 100%;
  /* 
  position: absolute; */
  /* bottom: 145px; */
  position: fixed;
  bottom: 103px;
  left: 0;
  right: 0;
  /* display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden; */
}

.fadeInDown {
  animation-name: fadeInDown;

  animation-duration: 0.5s;
  animation-timing-function: linear;
}

/* .mapCard__slider-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  overflow: hidden;
} */
/* .mapCardLeft {
  order:1;
  animation-name: slideInLeft;
 
  animation-duration: 0.2s;
  animation-timing-function: linear;
} */

.mapCard_dis {
  display: none;
}

.mapCard:hover {
  cursor: pointer;
}

.mapCard__boxImage {
  position: relative;
  overflow: hidden;
  width: 99px;
  height: 110px;
  border-radius: 10px;
}

.mapCard__image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 99px;
  height: 110px;
  /* aspect-ratio: 16/9; */

  object-fit: cover;
}

.mapCard__imageButton {
  position: absolute;
  width: 18px;
  height: 18px;
  margin: 0;
  padding: 0;
  top: 3px;
  left: 2px;
  z-index: 1;
  background: #f8f7f9;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}

.mapCard__wrapperImage {
  position: relative;
}

.mapCard__heartWrapper {
  width: 15px;
  height: 14px;
  position: absolute;
  top: 8px;
  right: 10px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.mapCard__title {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  padding: 0;
  margin: 0;
  margin-top: 3px;
  max-width: 170px;
}

.mapCard__subtitle {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  padding: 0;
  margin: 0;
  max-height: 60px;
  overflow: hidden;
}

.mapCard__wrapperTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
  margin-bottom: 4px;
}

.mapCard__wrapperCity {
  display: flex;
  width: 100%;
  gap: 5.82px;
  align-items: center;
}

.mapCard__wrapper {
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
  justify-content: space-between;
  height: 100%;
}

.mapCard__wrapperEndTitle {
  display: flex;
  width: 100%;
  gap: 3.92px;
  align-items: center;
  margin-bottom: 7px;
}

.mapCard__wrapperIcon {
  display: flex;
  justify-content: space-between;
}

.mapCard__IconText {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  margin: 0 10.16px 0 0;
  padding: 0;
}
.mapCard__IconTextLeft {
  margin-left: 0.29px;
}

.mapCard__priceTitle {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin: 0;
  padding: 0;
}

.mapCard__wrapperDescription {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.slideInLeft {
  animation-name: slideInLeft;

  animation-duration: 0.2s;
  animation-timing-function: linear;
}
