.containerReview {
  display: flex;
  padding: 0 20px;
  margin: 0 0 24px;
}

.review {
  width: 301px;
  background-color: #f8f7f9;
  border-radius: 10px;
  padding: 16px 17px 15px 19px;
  min-height: 87px;
  position: relative;
}

.name {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
}

.text {
  margin: 0 0 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 3px;
}

.show {
  display: block;
  cursor: pointer;
  border: none;
  margin: 31.5px auto 0;
  background-color: transparent;
  color: #3495cf;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.button_click {
  display: none;
  cursor: pointer;
  border: none;
  margin-bottom: 5px;
  background-color: transparent;
}

.buttonDelete {
  position: absolute;
  top: 16px;
  right: 16px;
  color: #3495cf;
  cursor: pointer;
  background-image: url('../../images_new/close.svg');
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  padding: 6.5px;
}

.containerImg {
  margin-top: 16px;
  display: flex;
  gap: 3px;
  flex-wrap: wrap;
}

.img {
  width: 61px;
  height: 61px;
  border-radius: 8px;
  object-fit: cover;
}
