.container{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #F8F7F9;
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 0 19px 28px;
    box-sizing: border-box;
    z-index: 20;
}

.button{
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    margin-top: 16px;
    height: 42px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.text{
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin: 7px 0 0;
}
