.createButton {
  margin: 36px auto 0;
  width: 100%;
  height: 117px;
  display: flex;
  flex-direction: column;
  border: none;
  filter: drop-shadow(0px 4px 4px rgba(154, 154, 154, 0.3));
  gap: 12px;
  align-items: center;
  background: #ffffff;
  box-shadow: 1px 3px 9px 2px rgba(118, 118, 118, 0.44);
  border-radius: 10px;
  padding: 0;
  cursor: pointer;
}

.createButtonImg {
  width: 40px;
  height: 40px;
  background: url('../../images_new/plus.svg');
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;
  color: #363636;
}

.createButtonText {
  margin: 19px 10px 0 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0;
  text-align: center;
}
