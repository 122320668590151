@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0px, 100%, 0px);
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.exMap__container {
  height: 466px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 auto;
}

.exMapRoutes {
  height: 466px;
  margin: 0 auto;
}

.exMap__containerFull {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: white;
}

.exMapRoutesFull {
  width: 100%;
  height: 100%;
}

.createPointMap {
  height: 100vh;
  cursor: pointer;
}

.fadeInDown {
  animation-name: fadeInDown;

  animation-duration: 0.5s;
  animation-timing-function: linear;
}

.createMapPanel {
  width: 334px;
  height: 131px;
  position: fixed;
  border-radius: 20px;
  padding: 10px 10px 0;
  border: 1px solid black;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  bottom: 50px;
  left: 0;
  right: 0;
  font-size: 14px;
  background: rgb(255, 255, 255);
  text-align: center;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
}

.createPointMap__address {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #363636;
}

#coords {
  margin: 0;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

#point {
  margin: 20px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #1b8086;
}

.createPointMap__button {
  width: 181px;
  height: 32px;
  cursor: pointer;
  margin: auto auto 10px;
  border-radius: 30px;
  border: none;
  background-color: #3495cf;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}

.visible {
  display: block;
}

.searchMap__button:hover {
  opacity: 0.7;
  transition: 0.7s;
}
