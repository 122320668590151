.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.content {
    padding: 0 5%;
    background-color: #F8F7F9;
    border-radius: 20px 20px 0 0;
    margin: 45px auto 27px auto;
    width: 90%;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    overflow-y: auto;
}

.text {
    text-align: center;
    margin: 34px auto 0 auto;
    width: 289px;
    font-size: 16px;
    line-height: 24px;
    color: #363636;
}

.containerButton {
    margin-top: 45px;
    display: flex;
    margin-bottom: 87px;
}
