.input {
    width: calc(100% - 20px);
    max-width: 420px;
    height: 61px;
    border: 0.5px solid #363636;
    border-radius: 10px;
    display: flex;
    justify-content: start;
    padding: 0 10px ;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.error {
    font-weight: 300;
    font-size: 14px;
    line-height: 30px;
    position: absolute;
    top: 45px;
    color: red;
}