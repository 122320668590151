.excursionCard {
  display: flex;
  flex-direction: column;
  margin: 0 0 24px;
  box-shadow: 0 4px 13px -5px #4f6573;
  border-radius: 10px;
  min-height: 360px;
  cursor: pointer;
}

.excursionCard:last-of-type {
  margin-bottom: 0;
}

.excursionCardContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.rating {
  display: flex;
  align-items: center;
}

.excursionCardHeader {
  display: flex;
  align-items: center;
  margin-right: 19px;
}

.excursionCardHeader:last-of-type {
  margin: 0;
}

.excursionCardImage {
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 10px 10px 0 0;
  object-fit: cover;
  height: 198px;
}

.containerMark {
  display: flex;
  align-items: center;
  margin-bottom: 16.5px;
}

.mark {
  width: 11px;
  height: 13px;
  margin-right: 9px;
}

.star {
  margin-right: 4px;
}

.imageWrapper {
  border-radius: 10px;
  width: 100%;
  position: relative;
}

.excursionCardDescription {
  display: flex;
  flex-direction: column;
  padding: 12px 12px 20px;
}

.excursionCardTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.excursionCardsSubtitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  padding: 0;
  margin: 0 0 0 6px;
}

.excursionCardType {
  margin: 0 0 0 4px;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.excursionCardRating {
  margin: 0 0 0 8px;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.heartWrapper {
  position: absolute;
  width: 30px;
  height: 30px;

  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.containerLike {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 11px;
  top: 12px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.containerShare {
  height: 20px;
}

.button {
  width: 18px;
  height: 18px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 17px;
  height: 17px;
  margin-right: 8px;
}

.excursionCardLink {
  margin: 0;
  padding: 0;
  text-decoration: none;
  display: block;
  font-size: 0;
}

.excursionContainerIcon {
  display: flex;
  margin-bottom: 18.5px;
}

.image__hover:hover {
  cursor: pointer;
}

.price {
  margin: 0;
  text-align: end;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.priceText {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #979798;
  margin: 0;
}

.spanText {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  color: #363636;
}
