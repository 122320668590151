.btnInfo {
  position: relative;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  background: transparent;
  cursor: pointer;
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.help {
  position: absolute;
  box-sizing: border-box;
  top: 30px;
  left: -10px;
  width: 280px;
  max-width: 100%;
  padding: 15px 24px 15px 18px;
  background-color: #ffffff;
  border-radius: 8px;
  z-index: 2;
}

.help::before {
  content: '';
  position: absolute;
  left: calc(50% - 10px);
  top: -20px;
  border: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
}

.helpTitle {
  font-weight: 500;
}

.helpText {
  margin: 6px 0 0;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.drafts {
  display: flex;
  align-items: center;
  height: calc(100vh - 340px);
  padding: 0 20px;
  box-sizing: border-box;
}

.textDefault {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  margin: 0 0 14px;
  text-align: center;
}
