.containerInput{
    box-shadow: 0 2px 7px #7676764F;
    border-radius: 30px;
    display: flex;
    align-items: center;
    padding: 10px 18px 8px 13px;
    width: 100%;
    max-width: 420px;
    box-sizing: border-box;
    margin: 0 auto;
}

.buttonSearch{
    background-color: transparent;
    border: none;
    padding: 0;
    width: 23px;
    height: 23px;
}

.input{
    margin: 0 0 0 7px;
    padding: 0;
    border: none;
    outline: none;
    min-width: 212px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    width: 84%;
}

.filter{
    width: 19px;
    height: 14px;
    cursor: pointer;
}

@media screen and (max-width: 340px) {
    .input{
        min-width: 190px;
    }    
}