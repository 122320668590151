.containerArea {
    position: relative;
    margin-bottom: 12px;
    width: 100%;
    display: flex;
}

.textarea {
    width: 100%;
    border-radius: 10px;
    padding: 12px;
    outline: none;
    height: 137px;
    font-size: 16px;
    resize: none;
    position: relative;
    border: 0.5px solid #2F2F2F;
    box-sizing: border-box;
    margin: 0;
}

.error {
    border: 0.5px solid #FE5F55;
    color: #FE5F55;
}

.error::placeholder {
    color: #FE5F55;
}

.symbols {
    position: absolute;
    margin: 0;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    right: 5%;
    bottom: 8%;
    color: #979798;
}
