.file{
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;
    margin-top: 16px;
}

.error {
    border: 0.5px solid #FE5F55;
    color: #FE5F55;
}

.error::placeholder {
    color: #FE5F55;
}

.errorPlaceholder {
    color: #FE5F55;
}

.text{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0 0 0 12px;
}

.photoContainer{
    display: flex;
    gap: 5px;
    margin-top: 8px;
    position: relative;
}

.photo{
    position: relative;
}

.image{
    width: 80px;
    height: 80px;
    border-radius: 10px;
    object-fit: cover;
    background: #D4E9F5;
}

.delete{
    position: absolute;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    left: 4px;
    top: 4px;
    border: none;
}