.buttonBlue {
  margin: 0 auto;
  width: 335px;
  height: 51px;
  max-width: 420px;
  background: #3495cf;
  border-radius: 30px;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  cursor: pointer;
}

.buttonBlue:disabled {
  background: #d1d1d8;
  color: #979798;
}

.button {
  margin: 0 auto;
  width: 335px;
  height: 51px;
  max-width: 420px;
  background: transparent;
  border: 1px solid #363636;
  border-radius: 30px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #363636;
  cursor: pointer;
}

.button:disabled {
  border-color: #d1d1d8;
  color: #d1d1d8;
}
