.containerStars{
    display: flex;
    margin: 0 auto 32px;
    width: 148px;
    justify-content: space-between;
}

.star{
    cursor: pointer;
}

.input{
    display: none;
}