.newEvent {
  padding: 24px 20px 100px;
}

.header {
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
}

.btnClose {
  margin: 0 0 0 7px;
  cursor: pointer;
  width: 17px;
}

.save {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #363636;
  opacity: 80%;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: underline;
}

.errorText {
  position: relative;
  top: -5px;
  color: red;
  font-size: 0.7rem;
}

.h1 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 24px 0;
}

.form {
  display: flex;
  flex-direction: column;
}

.textForm {
  color: #363636;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 0;
  margin-bottom: 48px;
}

.link {
  color: #3495cf;
  text-decoration: underline;
}

.input {
  min-height: 61px;
  width: 100%;
  border: 0.5px solid #363636;
  border-radius: 10px;
  font-size: 16px;
  outline: none;
  padding: 18px 0 19px 10px;
  box-sizing: border-box;
  margin: 0 0 16px;
}

.containerArea {
  position: relative;
  margin-bottom: 12px;
  width: 100%;
  display: flex;
}

.textarea {
  width: 100%;
  border-radius: 10px;
  padding: 12px;
  outline: none;
  height: 137px;
  font-size: 16px;
  resize: none;
  position: relative;
  border: 0.5px solid #2f2f2f;
  box-sizing: border-box;
  margin: 0;
}

.symbols {
  position: absolute;
  margin: 0;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  right: 5%;
  bottom: 8%;
  color: #979798;
}

.text {
  font-size: 12px;
  line-height: 16px;
  color: #363636;
}

.blueText {
  font-weight: 600;
  color: #3495cf;
  text-decoration: underline;
}

.addPoint {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin: 0 0 40px;
  background-color: transparent;
  border: none;
  padding: 0;
}

.textAddPoint {
  margin: 0 0 0 13px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #3495cf;
  text-decoration: underline;
}

.inputRef {
  display: none;
}

.photoContainer {
  display: flex;
  gap: 5px;
  margin: 0 0 20px 20px;
}

.photo {
  position: relative;
}

.image {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  object-fit: cover;
}

.delete {
  position: absolute;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  right: 4px;
  top: 4px;
  border: none;
}

.button {
  margin: 40px auto 14px;
  width: 335px;
  height: 51px;
  background: #3495cf;
  border-radius: 30px;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  cursor: pointer;
}

.buttonMiss {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #363636;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-decoration: underline;
  opacity: 80%;
}

.error {
  border: 0.5px solid #fe5f55;
  color: #fe5f55;
}

.error::placeholder {
  color: #fe5f55;
}

.span {
  position: relative;
}

.spanContainer {
  display: flex;
  align-items: center;
}

::-webkit-input-placeholder {
  color: #979798;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

::-moz-placeholder {
  color: #979798;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

:-moz-placeholder {
  color: #979798;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

:-ms-input-placeholder {
  color: #979798;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
