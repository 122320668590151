.loadMoreText {
 
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        /* text-overflow: ellipsis; */
        /* white-space: nowrap; */
        overflow-wrap: break-word;
}

.showText {
        display: block
}

.noShowText {
        display: none;
}