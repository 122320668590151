.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(76, 76, 76, 69%);
  max-width: 420px;
  margin: 0 auto;
  transition: visibility 0s, opacity 0.5s linear;
  z-index: 100;
}

.container {
  width: 100%;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  max-width: 375px;
  display: flex;
  flex-direction: column;
}

.button {
  cursor: pointer;
  width: 17px;
}
