.wrapper {
  padding: 0 20px 100px;
}

.container {
  padding: 51px 0 48px;
}

.title {
  margin: 0 0 28px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

/* .container_img {
  display: flex;
  flex-direction: column;
} */

.img {
  width: 60%;
  max-width: 400px;
  margin: 0 auto 10px;
}

.subtitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
}
