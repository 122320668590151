.tabs {
  margin: 24px 0;
  display: flex;
  gap: 12px;
}

.tab {
  padding: 0;
  color: #979798;
  background: transparent;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  opacity: 0.8;
  cursor: pointer;
}

.active {
  color: #3495cf;
}
