.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.popup_opened {
  display: flex;
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  transition: visibility 0s, opacity 0.5s linear;
}

.containerPopup_opened {
  display: flex;
  flex-direction: column;
  visibility: visible;
  opacity: 1;
  max-width: 420px;
  margin: 0 auto;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(76, 76, 76, 69%);
  align-items: center;
  justify-content: center;
  transition: visibility 0s, opacity 0.5s linear;
  cursor: pointer;
}

.container {
  width: 100%;
  min-height: 598px;
  background-color: white;
  border-radius: 20px 20px 0 0;
  padding: 28px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  bottom: 0;
  position: fixed;
}

.btnClose {
  margin: 0 0 39px 4px;
  cursor: pointer;
  width: 17px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 0 32px;
}

.text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0 0 12px;
}

.containerArea {
  position: relative;
  margin-bottom: 12px;
  display: flex;
}

.textarea {
  background-color: #d4e9f5;
  width: 100%;
  border-radius: 10px;
  padding: 12px;
  outline: none;
  height: 137px;
  resize: none;
  position: relative;
  border: none;
  margin: 0 auto;
}

.symbols {
  position: absolute;
  margin: 0;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  right: 12px;
  bottom: 12px;
  color: #979798;
}

.buttonImg {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  width: 150px;
  padding: 0;
  margin-bottom: 16px;
}

.photoContainer {
  display: flex;
  gap: 5px;
}

.photo {
  position: relative;
}

.image {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  object-fit: cover;
}

.delete {
  position: absolute;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  left: 4px;
  top: 4px;
  border: none;
}

.buttonMiss {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #363636;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-decoration: underline;
  opacity: 80%;
}
