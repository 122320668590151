.popup {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.popup_opened {
  display: flex;
  max-width: 420px;
  margin: 0 auto;
  bottom: 0;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: end;
  transition: visibility 0s, opacity 0.5s linear;
}

.containerPopup_opened {
  display: flex;
  flex-direction: column;
  visibility: visible;
  opacity: 1;
  max-width: 420px;
  margin: 0 auto;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(76, 76, 76, 69%);
  align-items: center;
  justify-content: center;
  transition: visibility 0s, opacity 0.5s linear;
  cursor: pointer;
}

.container {
  width: 100%;
  height: 223px;
  background-color: white;
  border-radius: 20px 20px 0 0;
  padding: 21px 20px 0;
  box-sizing: border-box;
  position: fixed;
  max-width: 420px;
  bottom: 0;
  margin: 0 auto;
  right: 0;
  left: 0;
  z-index: 3;
}

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 20px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 0 10px;
}

.btnClose {
  left: 0;
  cursor: pointer;
}

.containerButton {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

.link {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  color: #363636;
  text-align: center;
}

.text {
  margin: 0;
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;
}

.containerCopy {
  display: flex;
  cursor: pointer;
  margin: 0 0 31px;
}

.imgCopy {
  margin-right: 11px;
}

.textCopy {
  opacity: 80%;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
  text-decoration: underline #363636;
}

.textCopyBlue {
  opacity: 80%;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
  text-decoration: underline #363636;
  color: #3495cf;
}
