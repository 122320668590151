.title{
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 24px;
}

.save{
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #363636;
    opacity: 80%;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-decoration:underline
}

.input{
    min-height: 61px;
    width: 100%;
    border: 0.5px solid #363636;
    border-radius: 10px;
    outline: none;
    font-size: 16px;
    padding: 18px 0 19px 10px;
    box-sizing: border-box;
    margin: 0 0 16px;
}

.containerArea{
    position: relative;
    margin-bottom: 12px;
    width: 100%;
    display: flex;
}

.textarea{
    width: 100%;
    border-radius: 10px;
    padding: 12px;
    outline: none;
    font-size: 16px;
    height: 137px;
    resize: none;
    position: relative;
    border: 0.5px solid #2F2F2F;
    box-sizing: border-box;
    margin: 0;
}

.symbols{
    position: absolute;
    margin: 0;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    right: 5%;
    bottom: 8%;
    color: #979798;
}

.inputPointContainer{
    min-height: 61px;
    width: 100%;
    border: 0.5px solid #363636;
    border-radius: 10px;
    padding: 0 0 0 14px;
    box-sizing: border-box;
    margin: 0 0 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.inputPoint{
    border: none;
    outline: none;
    width: 90%;
    font-size: 16px;
    height: 61px;
    padding: 0;
    margin-left: 7px;
    cursor: pointer;
    background-color: transparent;
}

.inputRef{
opacity: 0;
}

.containerFile{
    width: 100%;
    background: #D4E9F5;
    border-radius: 10px;
    padding: 26px 0 31px 13px;
    display: flex;
    flex-direction: column;
    margin: 0 0 14px;
    box-sizing: border-box;
}

.file{
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;
    margin-top: 16px;
}

.file:first-of-type{
    margin: 0;
}

.text{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0 0 0 12px;
}

.containerButton{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0 0 24px;
}

.audioContainer{
    display: flex;
    gap: 5px;
    margin-top: 8px;
    position: relative;
    width: 45%;
}

.photoContainer{
    display: flex;
    gap: 5px;
    margin-top: 8px;
    position: relative;
}

.photo{
    position: relative;
}

.image{
    width: 80px;
    height: 80px;
    border-radius: 10px;
    object-fit: cover;
    background: #D4E9F5;
}


.delete{
    position: absolute;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    left: 4px;
    top: 4px;
    border: none;
}

.error {
    border: 0.5px solid #FE5F55;
    color: #FE5F55;
}

.error::placeholder {
    color: #FE5F55;
}

.errorPlaceholder {
    color: #FE5F55;
}

.errorPlaceholder::placeholder {
    color: #FE5F55;
}

.photoText{
    margin: 0;
}

::-webkit-input-placeholder {color:#979798; font-size: 16px; font-weight: 500; line-height: 24px;}
::-moz-placeholder          {color:#979798; font-size: 16px; font-weight: 500; line-height: 24px;}
:-moz-placeholder           {color:#979798; font-size: 16px; font-weight: 500; line-height: 24px;}
:-ms-input-placeholder      {color:#979798; font-size: 16px; font-weight: 500; line-height: 24px;}
::placeholder { 
    color:#979798; font-size: 16px; font-weight: 500; line-height: 24px;
}

textarea:-moz-placeholder, 
textarea::-moz-placeholder, 
textarea:-ms-input-placeholder, 
textarea::-webkit-input-placeholder, 
textarea::placeholder { 
    color:#979798; font-size: 16px; font-weight: 500; line-height: 24px;
}

