.file{
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;
    margin-top: 16px;
}

.photoText{
    margin: 0;
}

.text{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0 0 0 12px;
}

.audioContainer{
    display: flex;
    gap: 5px;
    margin-top: 8px;
    position: relative;
    width: 95%;
}

.deleteAudio{
    position: absolute;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    right: 4px;
    top: 4px;
    border: none;
}
