@import url(./vendor/fonts/opensans.css);

body {
  color: #363636;
  max-width: 420px;
  margin: 0 auto;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

input,
textarea {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

input::placeholder,
textarea::placeholder {
  font-family: 'Open Sans', sans-serif;
}

button {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  border: 0;
}

ul {
  padding: 0;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
}

h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin: 40px 0 32px;
}

p {
  font-weight: 274;
  font-size: 18px;
  line-height: 27px;
}
