.button{
    width: 100px;
    height: 40px;
    position: fixed;
    top: 133px;
    left: 50%;
    background-color: #3495CF;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    color: #F8F7F9;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
}

.text{
    margin: 0;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}