.containerOpen {
  width: 100%;
  margin: 0 auto;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100000;
  top: 0;
  bottom: 0;
  background-color: white;
  overflow-y: auto;
}

.continerInfo {
  padding: 20px 20px 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.img {
  width: 263px;
  height: 268px;
  border-radius: 26px;
  margin: 99px auto 30px;
  object-fit: cover;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin: 0 auto;
}

.btnClose {
  left: 0;
  cursor: pointer;
}

.containerManagement {
  position: relative;
  background-color: #d4e9f5;
  padding: 22px 21px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 36%;
}

.container {
  display: flex;
}

.containerIcon {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.volume {
  background-image: url('../../../images_new/volume.png');
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-color: transparent;
  width: 22px;
  height: 16px;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.volumeContainer {
  display: none;
}

.progressInput {
  width: 100%;
}

.volumeContainerHidden {
  position: absolute;
  width: 24px;
  height: 123px;
  background: #d4e9f5;
  border-radius: 10px;
  display: flex;
  top: -140px;
  left: 15px;
  align-items: center;
  justify-content: center;
}

.valueInput {
  -webkit-appearance: none;
  background: -webkit-linear-gradient(
    bottom,
    #3495cf 0%,
    #3495cf 50%,
    #fff 50%,
    #fff 100%
  );
  width: 3px;
  height: 93px;
  cursor: pointer;
  align-self: center;
  margin: 0;
  border-radius: 10px;
}

/* .valueInput{
    -webkit-appearance: none;
    background-color: #3495CF;
} */

.valueInput::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 50%;
  background-color: #d1d1d8;
  width: 9px;
  height: 9px;
}

.vector {
  background-image: url('../../../images_new/vector.png');
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-color: transparent;
  width: 22px;
  height: 16px;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.dot {
  background-image: url('../../../images_new/dot.png');
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-color: transparent;
  margin-left: 23.5px;
  cursor: pointer;
  padding: 0;
  width: 3px;
  height: 16px;
}

.containerProgress {
  background-color: white;
  width: 100%;
  height: 3px;
  margin: 0 0 10px;
  cursor: pointer;
}

.progress {
  width: 20%;
  background-color: #3495cf;
  height: 3px;
}

.containerTime {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.time {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.containerButton {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prev {
  background-image: url('../../../images_new/arrowLeft.png');
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-color: transparent;
  width: 23px;
  height: 25px;
  cursor: pointer;
  margin-right: 40px;
  padding: 0;
}

.prev:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

.play {
  border: none;
  background-color: transparent;
  width: 58px;
  height: 58px;
  cursor: pointer;
  padding: 0;
}

.next {
  background-image: url('../../../images_new/arrowRight.png');
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-color: transparent;
  width: 23px;
  height: 25px;
  cursor: pointer;
  margin-left: 40px;
  padding: 0;
}

.next:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

.control_s {
  width: 25px;
  height: 25px;
}

.prev_s {
  margin-right: 20px;
}

.next_s {
  margin-left: 20px;
}

.container_s {
  width: 100%;
  margin: 0 auto;
}

.play_s {
  width: 40px;
  height: 40px;
}

.time_s {
  font-size: 8px;
  line-height: 11px;
  margin: 0;
}

.containerTime_s {
  margin-bottom: 0;
}
