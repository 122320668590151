.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 199px);
  padding: 0 20px;
  box-sizing: border-box;
}

.text {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  margin: 0 0 14px;
  text-align: center;
}

.textLink {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #3495cf;
  cursor: pointer;
  margin: 0;
  text-decoration: underline;
}
