.map__container {
    width: 100%;
    margin: 0 auto;
 height: calc(100vh - 85px); /* Fallback for browsers that do not support Custom Properties */

 height: calc(var(--vh, 1vh) * 100 - 85px);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.mapAllRoutes {
    /* min-height: 300px;
    max-height: 60vh; */
    width: 100%;
   height: 100%;
}