.list {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.li:last-child .linkName{
  opacity: 0.5;
}

.li:last-child svg{
  opacity: 0.5;
}

.link {
  display: flex;
  align-items: center;
}

.linkName {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  margin: 0 auto 0 12px;
  padding: 0;
  color: #363636;
  opacity: 0.8;
}
