.buttonExcursion {
  margin: 0;
  width: 216px;
  height: 51px;
  background: #3495cf;
  border-radius: 30px;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
